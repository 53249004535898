import {
  CustomerStatus,
  EscrowAccountFragment,
  FundingSourceFragment,
  FundingSourceStatus,
  PaymentOccasionFragment,
  PaymentOccasionStatus,
  TransactionFragment,
  TransactionStatus,
  TransactionTemplateFragment,
  TransactionTemplateStatus,
  UserFragment,
  WebhookAttemptFragment,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { Badge, BadgeProps } from "@mantine/core"

interface FundingSourceStatusBadgeProps extends BadgeProps {
  fundingSource: FundingSourceFragment
}

export function FundingSourceStatusBadge({
  fundingSource,
  ...rest
}: FundingSourceStatusBadgeProps) {
  const fundingSourceStatusMap = {
    [FundingSourceStatus.Unverified]: {
      color: "gray",
      label: "Unverified",
    },
    [FundingSourceStatus.PendingAutomaticMicrodeposit]: {
      color: "yellow",
      label: "Pending Auto Microdeposit",
    },
    [FundingSourceStatus.PendingManualMicrodeposit]: {
      color: "yellow",
      label: "Pending Microdeposit",
    },
    [FundingSourceStatus.Verified]: {
      color: "aqua",
      label: "Verified",
    },
    [FundingSourceStatus.Error]: {
      color: "red",
      label: "Error",
    },
  }
  const mdStatusMap = {
    processed: {
      color: "green",
      label: "Microdeposit Ready",
    },
    pending: {
      color: "yellow",
      label: "Pending Microdeposit",
    },
    VERIFIED: {
      color: "aqua",
      label: "Verified",
    },
  }
  const passProps =
    (fundingSource.status === FundingSourceStatus.Unverified
      ? fundingSource.mdVerifiedAt
        ? mdStatusMap.VERIFIED
        : mdStatusMap[fundingSource.microdepositStatus]
      : fundingSourceStatusMap[fundingSource.status]) ||
    fundingSourceStatusMap[FundingSourceStatus.Error]

  return (
    <Badge {...rest} color={passProps?.color}>
      {passProps?.label}
    </Badge>
  )
}

interface EscrowAccountStatusBadgeProps extends BadgeProps {
  escrowAccount: EscrowAccountFragment
}

export function EscrowAccountStatusBadge({
  escrowAccount,
  ...rest
}: EscrowAccountStatusBadgeProps) {
  if (
    escrowAccount.fundingSource &&
    escrowAccount.fundingSource.status !== FundingSourceStatus.Verified
  ) {
    return (
      <FundingSourceStatusBadge
        fundingSource={escrowAccount.fundingSource}
        {...rest}
      />
    )
  }

  const passProps = escrowAccount.active
    ? {
        color: "aqua",
        label: "Active",
      }
    : {
        color: "gray",
        label: "Inactive",
      }

  return (
    <Badge {...rest} color={passProps?.color}>
      {passProps?.label}
    </Badge>
  )
}

interface PaymentOccasionStatusBadgeProps extends BadgeProps {
  paymentOccasion: PaymentOccasionFragment
}

export function PaymentOccasionStatusBadge({
  paymentOccasion,
  ...rest
}: PaymentOccasionStatusBadgeProps) {
  const passProps = {
    [PaymentOccasionStatus.Active]: {
      color: "aqua",
      label: "Active",
    },
    [PaymentOccasionStatus.Inactive]: {
      color: "gray",
      label: "Inactive",
    },
  }[paymentOccasion.status]

  return (
    <Badge {...rest} color={passProps?.color}>
      {passProps?.label}
    </Badge>
  )
}

interface TransactionStatusBadgeProps extends BadgeProps {
  transaction: TransactionFragment
}

export function TransactionStatusBadge({
  transaction,
  ...rest
}: TransactionStatusBadgeProps) {
  const passProps = {
    [TransactionStatus.ApprovalExpired]: {
      color: "red",
      label: "Expired",
    },
    [TransactionStatus.ApprovalPending]: {
      color: "yellow",
      label: "Needs Approval",
    },
    [TransactionStatus.ApprovalRejected]: {
      color: "red",
      label: "Rejected",
    },
    [TransactionStatus.Authorized]: {
      color: "green",
      label: "Authorized",
    },
    [TransactionStatus.Blocked]: {
      color: "red",
      label: "Blocked",
    },
    [TransactionStatus.Canceled]: {
      color: "dark",
      label: "Canceled",
    },
    [TransactionStatus.Cleared]: {
      color: "green",
      label: "Cleared",
    },
    [TransactionStatus.Deposited]: {
      color: "aqua",
      label: "Deposited",
    },
    [TransactionStatus.Failed]: {
      color: "red",
      label: "Failed",
    },
    [TransactionStatus.Initiated]: {
      color: "green",
      label: "Initiated",
    },
    [TransactionStatus.Pending]: {
      color: "gray",
      label: "Pending",
    },
  }[transaction.status]

  return (
    <Badge {...rest} color={passProps?.color}>
      {passProps?.label}
    </Badge>
  )
}

interface TransactionTemplateStatusBadgeProps extends BadgeProps {
  transactionTemplate: TransactionTemplateFragment
}

export function TransactionTemplateStatusBadge({
  transactionTemplate,
  ...rest
}: TransactionTemplateStatusBadgeProps) {
  if (transactionTemplate.transactions?.length) {
    return (
      <TransactionStatusBadge
        transaction={transactionTemplate.transactions[0]}
        {...rest}
      />
    )
  }

  // TODO: some logic to handle EMD template that is prefilled but I need to send

  const passProps = {
    [TransactionTemplateStatus.ApprovalPending]: {
      color: "yellow",
      label: "Needs Approval",
    },
    [TransactionTemplateStatus.ApprovalRejected]: {
      color: "red",
      label: "Rejected",
    },
    [TransactionTemplateStatus.Canceled]: {
      color: "dark",
      label: "Canceled",
    },
    [TransactionTemplateStatus.Completed]: {
      color: "gray",
      label: "Completed",
    },
    [TransactionTemplateStatus.Created]: {
      color: "gray",
      label: "Created",
    },
    [TransactionTemplateStatus.Deposited]: {
      color: "aqua",
      label: "Deposited",
    },
    [TransactionTemplateStatus.Draft]: {
      color: "gray",
      label: "Draft",
    },
    [TransactionTemplateStatus.Nil]: {
      color: "gray",
      label: "No Status",
    },
    [TransactionTemplateStatus.Pending]: {
      color: "yellow",
      label: "Pending",
    },
    [TransactionTemplateStatus.TransactionPending]: {
      color: "yellow",
      label: "Pending",
    },
  }[transactionTemplate.status]

  return (
    <Badge {...rest} color={passProps?.color}>
      {passProps?.label}
    </Badge>
  )
}

interface UserStatusBadgeProps extends BadgeProps {
  user: UserFragment
}

export function UserStatusBadge({ user, ...rest }: UserStatusBadgeProps) {
  const passProps = {
    [CustomerStatus.Document]: {
      color: "yellow",
      label: "Document",
    },
    [CustomerStatus.Retry]: {
      color: "yellow",
      label: "Retry",
    },
    [CustomerStatus.Suspended]: {
      color: "red",
      label: "Suspended",
    },
    [CustomerStatus.Unverified]: {
      color: "dark",
      label: "Unverified",
    },
    [CustomerStatus.Verified]: {
      color: "aqua",
      label: "Verified",
    },
  }[user.status]

  return (
    <Badge {...rest} color={passProps?.color}>
      {passProps?.label}
    </Badge>
  )
}

interface WebhookAttemptStatusBadgeProps extends BadgeProps {
  webhookAttempt: WebhookAttemptFragment
}

export function WebhookAttemptStatusBadge({
  webhookAttempt,
  ...rest
}: WebhookAttemptStatusBadgeProps) {
  return (
    <Badge
      {...rest}
      color={
        webhookAttempt.resultCode >= 200 && webhookAttempt.resultCode <= 299
          ? "green"
          : webhookAttempt.resultCode >= 300 && webhookAttempt.resultCode <= 399
          ? "blue"
          : webhookAttempt.resultCode >= 400
          ? "red"
          : "gray"
      }>
      {webhookAttempt.resultCode || "Queued"}
    </Badge>
  )
}
